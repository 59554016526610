// DON'T FORMAT ON SAVE. TRAILING SPACES ARE BEING USED FOR LINE BREAKS
import { MapPinIcon } from "@heroicons/vue/20/solid";

export const mapAddProperty = {
  order: 4,
  name: "Create a property",
  keywords: "map add property",
  subcategory: "The map",
  icon: MapPinIcon,
  iconColor: "text-rose-400",
  markdown: `# Create a property

  {% tablessContainer paddingStyles="px-5" %}
  **Who can use this feature?**

  {% inlineAppIcon iconName="user" /%}{% inlineRouterLink articleId="understand-reputation" sectionName="What reputation affects" %}**Reputable users**{% /inlineRouterLink %}.
  {% inlineAppIcon iconName="globe" /%}Available on {% inlineRouterLink %}**Big Map**{% /inlineRouterLink %} and {% inlineRouterLink %}**Compact Map**{% /inlineRouterLink %} within a property page.
  {% /tablessContainer %}

  {% inlineRouterLink articleId="what-is-a-property?" %}Properties{% /inlineRouterLink %} are essential to Tower Hunt. They allow users the freedom to express a multitude of real estate scenarios and can grow to encapsulate hundreds of linked datapoints.

  {% callout type="tip" marginStyles="mb-4" %}
  **New to Tower Hunt?** Take a moment to learn about {% inlineRouterLink articleId="what-is-a-property?" %}properties{% /inlineRouterLink %} and {% inlineRouterLink %}datapoints{% /inlineRouterLink %}.
  {% /callout %}

  {% callout type="caution" %}
  **Important:** Before you add a new property, look around nearby to make sure it doesn't already exist. Then, consider {% inlineRouterLink articleId="organize-properties" %}how your property should be organized{% /inlineRouterLink %}.
  {% /callout %}

  ## Create a property by pointing to a location

  If you have {% inlineRouterLink %}permission to create a property{% /inlineRouterLink %} follow the steps below.

  {% tablessContainer marginStyles="mb-4" %}
  1. Zoom the map in until the **+ Property** button appears next to the {% inlineRouterLink %}search bar{% /inlineRouterLink %}, then click it.
  2. Point to the desired location on the map. You'll see a red marker attached to your mouse pointer.
  3. Click the map to place the marker. Tower Hunt will attempt to suggest a name for the property. Revise this as needed.
  4. Click **Save** to proceed to the new property's page.
  {% /tablessContainer %}

  {% callout type="tip" %}
  **Keyboard shortcut:** You can also press {% inlineKeyboardShortcut %}P{% /inlineKeyboardShortcut %} to begin creating a property.
  {% /callout %}

  ### How it works

  {% inlineVideo videoSource="https://assets.towerhunt.com/site/createPropertyByClick0.mp4" marginStyles="my-4" /%}

  ## Create a property from a search location

  If you have {% inlineRouterLink %}permission to create a property{% /inlineRouterLink %} follow the steps below.

  {% tablessContainer marginStyles="mb-4" %}
  1. Select the {% inlineRouterLink %}search bar{% /inlineRouterLink %} and start typing an address of interest.
  2. Select a {% inlineAppIcon iconName="marker" %}**place search result**{% /inlineAppIcon %}.
  3. After the map recenters, keep zooming in until the **+ Property** button appears next to the search bar, then click it.
  4. Tower Hunt will attempt to suggest a name for the property. Revise this as needed.
  5. Click and drag the red marker to adjust the location as needed, then click **Save** to proceed to the new property's page.
  {% /tablessContainer %}

  ### How it works

  {% inlineVideo videoSource="https://assets.towerhunt.com/site/createPropertyFromSearch0.mp4" marginStyles="my-4" /%}

  {% callout type="tip" %}
  **Tip:** When you're ready to save, use the dropdown to click **Save and stay on map** to remain on the map. This allows you to quickly create multiple nearby properties.
  {% /callout %}

  ## Cancel property creation

  If you decide you don't want to create a property, click the **X Cancel** button next to the search bar.

  {% callout type="tip" %}
  **Keyboard shortcut:** You can also press {% inlineKeyboardShortcut %}Esc{% /inlineKeyboardShortcut %} to cancel.
  {% /callout %}`,
};
